import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';

const StyledGrid = styled(Grid)`
  max-width: 89rem;
  width: 100%;
`;

const StyledFooter = styled.footer`
  background: var(--white);
  position: relative;
  font-size: 0.75rem;
  letter-spacing: 0.24;
  line-height: 20px;
  color: var(--black);

  @media (min-width: 64em) {
    height: 100px;
  }
`;

const StyledCol = styled(Col)`
  height: 100px;

  @media (max-width: 64em) {
    height: auto;
  }
`;

const InnerContainer = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-50%);

  @media (max-width: 64em) {
    font-size: 0.75rem;
    padding-bottom: 15px;
    text-align: center;
  }
`;

const InnerContainerRight = styled(InnerContainer)`
  text-align: right;

  @media (max-width: 64em) {
    font-size: 0.875rem;
    padding: 15px 0 20px;
  }
`;

const Footer = ({ siteTitle }) => (
  <StyledFooter>
    <StyledGrid fluid>
      <Row reverse>
        <Col lg={8} md={6} xs={12}>
          <InnerContainerRight>
            <a href="https://cloud.brewsterhjorth.com.au/index.php/login" target="blank" rel="noopener noreferrer">BHA web file service</a>
            <br />
            <a href="https://www.instagram.com/brewsterhjortharchitects/" target="blank" rel="noopener noreferrer">BHA Instagram</a>
          </InnerContainerRight>
        </Col>
        <StyledCol lg={4} md={6} xs={12}>
          <InnerContainer>
            {`© ${new Date().getFullYear()} ${siteTitle}. All rights reserved.`}
            <br />
            Designed by
            <a href="https://www.vanessabrewster.com/" target="blank" rel="noopener noreferrer"> Vanessa Brewster </a>
            built by
            <a href="https://srrycmpny.com/" target="blank" rel="noopener noreferrer"> Sorry Company</a>
            .
          </InnerContainer>
        </StyledCol>
      </Row>
    </StyledGrid>
  </StyledFooter>
);

Footer.propTypes = {
  siteTitle: PropTypes.string.isRequired,
};

export default Footer;
