import { Link } from 'gatsby';
import React from 'react';
import { useLocation } from '@reach/router';
import styled from 'styled-components';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import Nav from './nav';
import Logo from '../images/BHA_2014_LOGO_WHITE_A.svg';

const StyledGrid = styled(Grid)`
  max-width: 89rem;
  width: 100%;
`;

const StyledHeader = styled.header`
  background: ${(props) => (props.pathName === '/' ? 'transparent' : 'var(--bg-color)')};
  position: ${(props) => (props.pathName === '/' ? 'absolute' : 'relative')};
  top: ${(props) => (props.pathName === '/' ? '0' : 'unset')};
  left: ${(props) => (props.pathName === '/' ? '0' : 'unset')};
  width: ${(props) => (props.pathName === '/' ? '100%' : 'inherit')};
  z-index: 991;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.738) 19%, rgba(0, 0, 0, 0.541) 34%, rgba(0, 0, 0, 0.382) 47%, rgba(0, 0, 0, 0.278) 56.5%, rgba(0, 0, 0, 0.194) 65%, rgba(0, 0, 0, 0.126) 73%, rgba(0, 0, 0, 0.075) 80.2%, rgba(0, 0, 0, 0.042) 86.1%, rgba(0, 0, 0, 0.021) 91%, rgba(0, 0, 0, 0.008) 95.2%, rgba(0, 0, 0, 0.002) 98.2%, transparent 100%);
`;

const StyledRow = styled(Row)`
  height: 150px;
`;

const NavCol = styled(Col)`
  @media (max-width: 64em) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

const StyledCol = styled(Col)`
  height: inherit;

  @media (max-width: 64em) {
    z-index: 99;
  }
`;

const LogoContainer = styled.div`
  width: 198px;
  height: 100%;
  position: relative;
  z-index: 10;
`;

const LogoInnerContainer = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;

const Header = () => {
  const { pathname } = useLocation();

  return (
    <StyledHeader pathName={pathname}>
      <StyledGrid fluid>
        <StyledRow>
          <StyledCol lg={3} md={3} xs={8}>
            <LogoContainer>
              <LogoInnerContainer>
                <Link
                  to="/"
                >
                  <Logo />
                </Link>
              </LogoInnerContainer>
            </LogoContainer>
          </StyledCol>
          <NavCol lg={9} md={9} xs={4}>
            <Nav />
          </NavCol>
        </StyledRow>
      </StyledGrid>
    </StyledHeader>
  );
};

export default Header;
