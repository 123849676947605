import reset from 'styled-reset-advanced';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  ${reset}

  :root {
    --white: white;
    --black: black;
    --blue: #4E99FA;
    --mobile: 64em;
    --body-font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  }

  ::placeholder {
    color: var(--white);
    opacity: 1;
  }

  ::selection {
    color: var(--white);
    background: var(--blue);
  }

  body {
    background: var(--black);
    line-height: 1.5;
    font-family: var(--body-font-family);
  }

  body.prevent-scroll {
    overflow: hidden;
  }

  main {
    position: relative;
    min-height: 100vh;
  }

  .awssld {
    --loader-bar-color: var(--blue);
    --organic-arrow-color: var(--white);
    --content-background-color: var(--black);
  }

  a {
    text-decoration: none;
    
    &:hover,
    &:focus {
      color: var(--blue);
      transition: color .5s ease;
    }
  }

  .svg {
    fill: var(--white);

    &:hover,
    &:focus {
      fill: var(--blue);
      transition: fill .5s ease;
    }
  }

  .react-multiple-carousel__arrow {
    background: unset;
    z-index: 8;

    &:hover {
      background: transparent;

      &:before {
        color: var(--blue);
        transition: color .5s ease;
      }
    }

    &:before {
      font-size: 40px;
    }
  }

  .react-multiple-carousel__arrow--left {
    left: calc(1% + 1px);
  }

  .react-multiple-carousel__arrow--right {
    right: calc(1% + 1px);
  }
`;

export default GlobalStyle;
