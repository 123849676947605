import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import SearchIcon from '@material-ui/icons/Search';

const SearchFormWrapper = styled.div`
  top: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  visibility: hidden;
  transition: all .5s ease;
  transform: translateX(-100%);
  width: 100%;
  z-index: 9;

  @media (min-width: 64em) {
    background-color: rgba(0,0,0,0.83);
    height: 100%;
    position: fixed;
    transform: translateX(0);
  }

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
`;

const SearchFormCol = styled(Col)`
  position: relative;

  @media (min-width: 64em) {
    height: 100vh;
  }
`;

const Form = styled.form`
  width: 100%;

  @media (min-width: 64em) {
    bottom: 20px;
    display: flex;
    position: absolute;
  }
`;

const StyledButton = styled.button`
  transition: color .5s ease;

  @media (max-width: 64em) {
    display: none;
  }
  
  &:hover,
  &:focus {
    color: var(--blue);
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  && {
    cursor: pointer;
    font-size: 10rem;
  }
`;

const FormInput = styled.input`
  background-color: transparent;
  border: 0;
  color: var(--white);
  font-size: 1.5rem;
  font-family: var(--body-font-family);
  font-weight: 300;
  padding: 0;
  width: 100%;

  &:focus {
    outline: none;
  }

  @media (min-width: 64em) {
    font-size: 10rem;
  }
`;

const StyledGrid = styled(Grid)`
  max-width: 89rem;
  width: 100%;
`;

function SearchForm({ showSearch, setShowSearch }) {
  const [query, setQuery] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/search?query=${query}`);
    setShowSearch(false);
  };

  const handleQueryChange = (event) => {
    setQuery(event.currentTarget.value);
  };

  const inputRef = useRef(null);

  if (showSearch) {
    // Hack, but otherwise focus never fires!
    setTimeout(() => { inputRef.current.focus(); }, 500);
  }

  return (
    <SearchFormWrapper className={`${showSearch ? 'active' : 'hidden'}`}>
      <StyledGrid fluid>
        <Row>
          <SearchFormCol lg={12} md={12} xs={12}>
            <Form onSubmit={(e) => { handleSubmit(e); }}>
              <FormInput
                type="text"
                id="searchInput"
                autoFocus
                placeholder="search"
                ref={inputRef}
                onChange={(e) => { handleQueryChange(e); }}
              />
              <StyledButton type="submit">
                <StyledSearchIcon />
              </StyledButton>
            </Form>
          </SearchFormCol>
        </Row>
      </StyledGrid>
    </SearchFormWrapper>
  );
}

SearchForm.propTypes = {
  showSearch: PropTypes.bool.isRequired,
  setShowSearch: PropTypes.func.isRequired,
};

export default SearchForm;
