/* eslint-disable import/prefer-default-export */
export const Grid = {
  flexboxgrid: {
    gridSize: 12,
    gutterWidth: 2.5,
    outerMargin: 2,
    mediaQuery: 'only screen',
    container: {
      sm: 46,
      md: 61,
      lg: 89,
    },
    breakpoints: {
      xs: 0,
      sm: 48,
      md: 64,
      lg: 90,
    },
  },
};
