import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import SearchForm from './searchForm';

const List = styled.ul`
  display: flex;
  justify-content: flex-end;
  padding-top: 33px;

  @media (max-width: 64em) {
    background-color: var(--black);
    display: block;
    height: 100vh;
    padding: 150px 0 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    transition: all .3s ease-in-out;
    transform: translateX(100%);
    width: 0;
    z-index: 2;
  }
`;

const NavWrapper = styled.nav`
  &.is-open {
    .hamburger-inner {
      transform: rotate(45deg);
      transition: all .3s ease-in-out;

      &:before {
        top: 0;
        opacity: 0;
        transition: all .3s ease-in-out;
      }

      &:after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: all .3s ease-in-out;
      }
    }

    @media (max-width: 64em) {
      .nav-list {    
        width: 100%;
        transform: translateX(0);
      }
    }
  }
`;

const ListItem = styled.li`
  font-weight: 200;
  font-size: 1.125rem;
  color: var(--white);
  letter-spacing: .36;
  padding-left: 40px;

  a {
    transition: color .5s ease;
  }
  
  a:hover,
  a:focus {
    color: var(--blue);
  }

  @media (max-width: 64em) {
    font-size: 1.5rem;
    padding: 0 2rem 1.25rem;
    position: relative;
  }
`;

const SearchButton = styled.button`
  cursor: pointer;
  min-width: 16px;
  transition: color .5s ease;
  
  &:hover,
  &:focus {
    color: var(--blue);
  }

  &.active {
    float: right;
    position: relative;
    z-index: 10;
  }
`;

const Hamburger = styled.button`
  padding: 0;
  display: inline-block;
  cursor: pointer;
  transition: all .3s ease-in-out;
  color: inherit;
  height: 35px;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  position: relative;
  z-index: 9;

  @media (min-width: 64em) {
    display: none;
  }

  &:focus {
    outline: none;
  }
`;

const HamburgerBox = styled.span`
  width: 45px;
  height: 35px;
  display: inline-block;
  position: relative;
`;

const HamburgerInner = styled.span`
  display: block;
  top: 50%;
  margin-top: -2px;
  width: 45px;
  height: 3px;
  background-color: var(--white);
  border-radius: 0;
  position: absolute;
  transition: all .3s ease-in-out;

  &:before,
  &:after {
    content: "";
    display: block;
    width: 45px;
    height: 3px;
    background-color: var(--white);
    border-radius: 0;
    position: absolute;
    transition: all .3s ease-in-out;
  }

  &:before {
    top: -15px;
  }

  &:after {
    bottom: -15px;
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  && {
    font-size: 23px;
    margin-top: 4px;

    @media (max-width: 64em) {
      font-size: 26px;
    }
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  && {
    font-size: 23px;
    margin-top: 4px;
  }
`;

const Nav = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  return (
    <NavWrapper className={`${showMenu ? 'is-open' : ''}`} role="navigation">
      <Hamburger
        type="button"
        onClick={(e) => {
          e.preventDefault();
          setShowMenu(!showMenu);
        }}
      >
        <HamburgerBox>
          <HamburgerInner className="hamburger-inner" />
        </HamburgerBox>
      </Hamburger>
      <List className="nav-list">
        <ListItem>
          <Link to="/projects">
            projects
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/company">
            company
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/news">
            news
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/contact">
            contact
          </Link>
        </ListItem>
        <ListItem>
          <SearchForm showSearch={showSearch} setShowSearch={setShowSearch} />
          <SearchButton
            type="button"
            className={`${showSearch ? 'active' : ''}`}
            onClick={(e) => {
              e.preventDefault();
              setShowSearch(!showSearch);
            }}
          >
            {showSearch ? (
              <StyledCloseIcon />
            ) : (
              <StyledSearchIcon />
            )}
          </SearchButton>
        </ListItem>
      </List>
    </NavWrapper>
  );
};

export default Nav;
